@import 'style/variables/_queries.css';
@import 'style/variables/_colors.css';

/** Main container */
.container {
  background-color: $light-gray;
}

/** Workspace area */
.workspace {}

/** Form container */
.form {
  max-width: 700px;
  margin: 25px 0px;
}

/** Form header */
.form__header {
  margin-bottom: 7px;
}

.form__header_left {
  margin-top: auto;
}

.form__header_right {
  margin-top: auto;
  text-align: right;
}

/** Form body */
.form__body {
  width: 700px;
  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
  background: $white;
  border: 1px solid $light-gray-2;
}

/** Form title */
.form__title {
  font-size: 16px;
  font-weight: bold;
  color: $primary-blue;
}

/** Cancel button */
.form__cancel_button {
  font-size: 8px;
  font-weight: bold;
  color: $light-gray-2;
  background-color: transparent;
  padding: 2px;
  padding-right: 0px;

  &:hover{
    background-color: transparent;
    color: $primary-blue;
  }
}

/** Name field */
.form__name_field {
  position: relative;
}

.form__name_field_title {
  font-size: 16px;
  color: $light-gray-2;
  font-weight: bold;
  text-transform: none;
}

.form__name_field input {
  font-size: 18px;
  font-weight: bold;
  color: $dark-gray;
  text-transform: uppercase;
  padding-right: 100px;
}

.form__name_field_lettering {
  font-size: 15px;
  font-weight: bold;
  color: $light-gray-2;
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
}

/** Submit button */
.form__action_checkbox {
  margin-top: 20px;
}

/** Submit button */
.form__submit_button {
  height: 45px;
  margin-top: 40px;
}
