@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url(~fonts/Roboto-Regular.woff2) format('woff2'),
       url(~fonts/Roboto-Regular.woff) format('woff'),
       url(~fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(~fonts/MaterialIcons-Regular.woff) format('woff'),
       url(~fonts/MaterialIcons-Regular.ttf) format('truetype');
}
