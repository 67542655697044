@import '../variables/_zindex.css';

/* Elements that cover the entire screen */
.overshadow {
  z-index: $overshadow;
}

/* Dialogs and modals. */
.modal {
  z-index: $modal;
}

/* Elements usually triggered by hovering or activating inputs by the user. */
.overlay {
  z-index: $overlay;
}

/* Elements that support the overall layout, such as a fixed header or footer. */
.layout {
  z-index: $layout;
}

/* Elements that stand out for a particular reason. */
.raised {
  z-index: $raised;
}

/** Reserved for the background cover. */
.cover {
  z-index: $cover;
}
